<template>
  <div>
    <!-- Error Handler Alert -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(value, valueIndex) in errors_back" :key="valueIndex">
          <li v-for="(val, valIndex) in value" :key="valIndex">{{ val }}</li>
        </ul>
      </div>
    </b-alert>
    <!-- validtion submit  -->
    <b-alert v-height-fade.appear fade :show="variantsCounter" @dismissed="variantsCounter = false" variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">Please Add at least one variant</div>
    </b-alert>
    <!--Bulk Action Collapse Section -->
    <b-row>
      <b-col>
        <app-collapse accordion :type="collapseType" class="pb-1">
          <app-collapse-item v-if="authPermissions.includes('update-product-status')" title="Status Actions"
            class="rounded-lg">
            <div class="custom-search">
              <b-row class="py-1">
                <b-col v-if="authPermissions.includes('update-product-status')" md="6">
                  <b-row>
                    <b-col>
                      <h3>Bulk Status Update</h3>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Status" label-for="Status">
                        <v-select v-model="bulkActionStatus" :options="actionForm.bulkOptions"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-button v-b-modal.modal-active class="px-5 mx-5 btn-color" @click="deDuctCountOfVariant()">
                        Submit
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-modal v-if="selectedRowsId.length > 0" id="modal-active" cancel-variant="outline-secondary"
                    @ok="applyBulkStatusUpdate()" ok-title="Ok" cancel-title="Close" centered
                    title="Update Product Status">"are you sure you want to Change status of
                    {{ selectedRowsId.length }} Variants?
                  </b-modal>
                </b-col>
              </b-row>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>

    <!-- Filter Collapse Section-->
    <b-row>
      <b-col>
        <app-collapse accordion :type="collapseType" class="pb-1">
          <app-collapse-item title="Filter" class="rounded-lg">
            <div class="custom-search">
              <b-row class="py-1">
                <b-col md="4">
                  <b-form-group label="Brands" label-for="Brands">
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="brands"
                      :selectable="(option) => !option.value.includes('select_value')
                        " :state="brands == null ? false : true" v-model="brandModel" />
                  </b-form-group>
                </b-col>
                <!-- <b-col md="4">
                    <b-form-group label="User" label-for="User">
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        :options="users"
                        v-model="userModel"
                      />
                    </b-form-group>
                  </b-col> -->
                <b-col md="4">
                  <b-form-group label="Category" label-for="Category">
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="categories"
                      v-model="categoriesModel" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Status" label-for="Status">
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="statusData"
                      v-model="statusModel" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Item" label-for="Item">
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="itemsBucket"
                      v-model="itemsModel" />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Min Quantity" label-for="Min Quantity">
                    <b-form-input :min="minQuan" :max="maxQuan" v-model="minQuan" type="number" />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Max Quantity" label-for="Max Quantity">
                    <b-form-input :min="minQuan" :max="maxQuan" v-model="maxQuan" type="number" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Min Price" label-for="Max">
                    <b-form-input :min="minPrice" :max="maxPrice" :value="minPrice" v-model="minPrice" type="number" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Max Price" label-for="Max">
                    <b-form-input :min="minPrice" :max="maxPrice" :value="maxPrice" v-model="maxPrice" type="number" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-row class="pt-1">
                    <b-col md="4" class="pt-1">
                      <h4>Type :</h4>
                    </b-col>
                    <b-col md="8" class="pt-1 d-flex">
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group class="d-flex" id="checkbox-group-2" button-variant="outline-primary"
                          :aria-describedby="ariaDescribedby" name="flavour-2">
                          <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios"
                            value="new">New</b-form-radio>
                          <b-form-radio class="ml-1" v-model="selected" :aria-describedby="ariaDescribedby"
                            name="some-radios" value="used">Used</b-form-radio>
                          <b-form-radio class="ml-1" v-model="selected" :aria-describedby="ariaDescribedby"
                            name="some-radios" value="all">All</b-form-radio>
                        </b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="12" class="d-flex justify-content-end">
                  <b-button class="px-3" variant="outline-dark" @click="resetFilter()">Reset</b-button>
                  <b-button class="btn-color ml-3" @click="fetchData()">
                    Apply Filter
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row>
      <b-col cols="12">
        <b-card-code title="Main Table" id="main-table">
          <!-- sorting action -->
          <b-row class="">
            <b-col md="6">
              <b-form-group label="Sort By" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="sortBySelect" class="d-flex align-items-center">
                <b-input-group class="d-flex">
                  <b-form-select id="sortBySelect" @change="fetchData" v-model="sortBy" value="desc" size="sm"
                    :options="sortOptionss">
                  </b-form-select>
                  <b-form-select @change="fetchData" v-model="orderBy" value="updated_at" size="sm"
                    :options="orderOptions">
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- search input -->
            <b-col md="3">
              <div class="ml-auto mr-2">
                <b-dropdown style="height: 2.142rem; line-height: 0.5" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  @click="RemoveExitem" right variant="primary" size="sm" class="dropdown-icon-wrapper">
                  <template #button-content>
                    <feather-icon icon="PrinterIcon" size="16" class="align-middle" />
                  </template>
                  <download-excel :data="exportItems">
                    <b-dropdown-item>
                      <feather-icon icon="DownloadIcon" /> Download
                      Excel</b-dropdown-item>
                  </download-excel>
                  <b-dropdown-divider />
                </b-dropdown>
              </div>
            </b-col>
            <b-col md="3">
              <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block"
                      @keyup="searchFetchData()" />
                  </div>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <!-- Table component -->
          <vue-good-table ref="my-table" :columns="columns" :rows="items" max-height="90vh"
            style="min-height: 30vh !important" styleClass="vgt-table striped" theme="polar-bear" :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }" :sort-options="{
  enabled: false,
}" :pagination-options="{
  enabled: true,
  perPage: perPage,
  setCurrentPage: currentPage,
}">
            <!-- Slot: Table Row -->
            <template slot="table-row" slot-scope="props">
              <!-- Column: cover -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-if="props.column.field === 'item_cover'">
                <b-img-lazy width="auto" height="50" :src="props.row.item_cover" />
              </div>
              <!-- Column: id -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'id'">
                {{ props.row.id }}
              </div>
              <!-- Column: SKU -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'sku'">
                {{ props.row.sku }}
              </div>
              <!-- Column: status -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'status'">
                <b-badge :class="[
                  props.row.status === 'enabled' ? 'bg-primary' : '',
                  props.row.status === 'review' ? 'bg-success' : '',
                  props.row.status === 'disabled' ? 'bg-dark' : '',
                  props.row.status === 'rejected' ? 'bg-danger' : '',
                ]">
                  {{ props.row.status }}
                </b-badge>
              </div>
              <!-- Column: brand_logo -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'brand_logo'">
                <b-img-lazy width="auto" height="20" :src="props.row.brand_logo" />
              </div>
              <!-- Column: category -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1"
                v-else-if="props.column.field === 'category_name'">
                <b-badge variant="info">
                  {{ props.row.category_name }}
                </b-badge>
              </div>
              <!-- Column: Item -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'item_name'">
                {{ props.row.item_name }}
              </div>
              <!-- Column: User -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1"
                v-else-if="props.column.field === 'user_seller_name'">

                <a :href="'/User/Show/' + props.row.user_id">
                  {{ props.row.user_seller_name }}
                </a>
              </div>
              <!-- Column: is_new -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'is_new'">
                <b-badge :variant="props.row.is_new ? 'light-success' : 'light-primary'">
                  {{ props.row.is_new ? 'New' : 'Used' }}
                </b-badge>
              </div>
              <!-- Column: Name -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'product_name'">
                {{ props.row.product_name }}
              </div>
              <!-- Column: Expire Date -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1"
                v-else-if="props.column.field === 'variant_end_date'">
                {{ props.row.variant_end_date }}
              </div>
              <!-- Column: Battery Health -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1 text-primary"
                v-else-if="props.column.field === 'battery_health'">
                {{ props.row.battery_health }}
              </div>
              <!-- Column: Imei -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1 text-primary"
                v-else-if="props.column.field === 'imei'">
                {{ props.row.imei }}
              </div>
              <!-- Column: Sim Cart Value -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1 text-primary"
                v-else-if="props.column.field === 'sim_cart_value'">
                {{ props.row.sim_cart_value }}
              </div>
              <!-- Column: Quantity	 -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'quantity'">
                {{ props.row.quantity }}
              </div>
              <!-- Column: original price -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1"
                v-else-if="props.column.field === 'original_price'">
                {{ formatPrice(props.row.original_price) }}
              </div>
              <!-- Column: sale price -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'sale_price'">
                {{ formatPrice(props.row.sale_price) }}
              </div>
              <!-- Column: original Discount -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'offer_amount'">
                {{ formatPrice(props.row.offer_amount) }}
              </div>
              <!-- Column: sale profit -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'profit'">
                {{ formatPrice(props.row.profit) }}
              </div>
              <!-- Column: is portal  -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'is_portal'">
                <b-badge v-if="props.row.is_portal" variant="primary">Portal</b-badge>
                <b-badge v-else variant="secondary">In Store</b-badge>
                <!-- {{ formatPrice(props.row.profit) }} -->
              </div>
              <!-- Column: Cretaed At -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'created_at'">
                {{ props.row.created_at }}
              </div>
              <!-- Column: Updated At -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'updated_at'">
                {{ props.row.updated_at }}
              </div>
              <!-- Column: Action -->
              <div @click="onRowClick(props)" class="d-block h-100 p-1" v-else-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item :to="{
                      path: '/' +
                        (props.row.is_new ? 'New' : 'Used') +
                        '/ShowProduct/variant/show/' +
                        props.row.id,
                    }">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span>Show Product</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="!props.row.is_new" :href="props.row.report_image" target="_blank">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span>Report</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="props.row.is_portal" :href="WebsiteLink + props.row.variant_slug"
                      target="_blank">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span> show in Website </span>
                    </b-dropdown-item>

                  </b-dropdown>
                </span>
              </div>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select v-model="perPage" :options="['50', '100', '500']" class="mx-1" @input="filteredData()" />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <!-- pagination -->
                <div>
                  <b-pagination :field="1" v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number
                    last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                    @input="filteredData()">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card-code>
      </b-col>
    </b-row>
    <!-- update price in table for variant -->
    <b-modal id="modal-prevent-closing" hide-footer ref="modal" centered title="Variant Details" size="lg">
      <b-row v-if="variantData">
        <b-col md="4">
          <b-img-lazy :src="variantData.item_cover" class="img-fluid" />
        </b-col>
        <b-col md="4">
          <p class="text-primary h4 mt-1">
            SKU: <span class="h5">{{ variantData.sku }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Name: <span class="h5">{{ variantData.product_name }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Type:
            <span class="h5">{{
              variantData.is_new == true ? 'New' : 'Used'
            }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Status:
            <b-badge variant="primary">{{ variantData.status }}</b-badge>
          </p>

          <p class="text-primary h4 mt-1" v-if="!variantData.is_new">
            Battery Health:
            <span class="h5">{{ variantData.battery_health }}</span>
          </p>
          <p class="text-primary h4 mt-1" v-if="variantData.is_new">
            Warranty:
            <span class="h5">{{ variantData.warranty_type }}</span>
          </p>
        </b-col>
        <b-col md="4">
          <p class="text-primary h4 mt-1">
            User :
            <b-badge variant="warning">{{ variantData.user_seller_name }}</b-badge>
          </p>
          <p class="text-primary h4 mt-1">
            Sale Price: <span class="h5">{{ variantData.sale_price }} EGP</span>
          </p>
          <p class="text-primary h4 mt-1">
            Seller Price:
            <span class="h6">{{
              parseInt(variantData.sale_price) - parseInt(variantData.profit)
            }}
              EGP</span>
          </p>
          <p class="text-primary h4 mt-1">
            Profit: <span class="h5">{{ variantData.profit }} EGP</span>
          </p>
          <p class="text-primary h4 mt-1">
            Quantity: <span class="h5">{{ variantData.quantity }}</span>
          </p>
          <p class="text-primary h4 mt-1">
            Report:
            <b-badge variant="info" @click="openReport(variantData.report_image)" class="h5 pointer btn">Open</b-badge>
          </p>
        </b-col>
      </b-row>


    </b-modal>
  </div>
</template>
  
<script>
import { required } from '@validations'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
export default {
  components: {
    VueGoodTable,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      required,
      WebsiteLink: 'https://mobilemasr.com/products/show/',
      variantData: null,
      varaintDataPrice: {
        vendorPayout: null,
        portalPrice: null,
        rateEGP: null,
        rateInPercent: null,
      },
      authPermissions: null,
      actionForm: {
        originalPrice: '',
        offerAmount: '',
        rate: '',
        rateType: '',
        offerType: '',
        userNumber: '',
        startDate: '',
        endDate: '',
        bulkOptions: ['disabled', 'rejected', 'blocked'],
      },
      filterForm: {
        brands: [],
        // users: [],
        categories: [],
        status: [],
        items: [],
        minQuantity: null,
        maxQuantity: null,
        minPrice: null,
        maxPrice: null,
        selected: [],
      },
      selectedRowsId: [],
      variantsBucket: [],

      bulkActionStatus: [],
      offerTypeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      rateTypeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      brandsData: '',
      brands: [],
      brandModel: {
        text: 'Select Brand',
        value: '',
      },
      // usersData: '',
      userModel: {
        text: 'Select User',
        value: '',
      },
      categoriesData: '',
      categories: [],
      categoriesModel: { text: 'Select Category', value: '' },
      statusData: [],
      statusModel: '',
      itemsData: '',
      itemsBucket: [],
      itemsModel: { text: 'Select Item', value: '' },
      minQuan: 0,
      maxQuan: 10,
      minPrice: '',
      maxPrice: '',
      type: '',
      item_cover: '',
      offer_amount: '',
      selected: 'all',
      selectedClassification: 'all',
      data: '',
      dateDefault: null,
      collapseType: 'default',
      totalRows: 1,
      pageLength: 5,
      dir: false,
      searchTimer: null,
      // min_quantity: 0,
      // max_quantity: 10,
      sortBy: 'desc',
      orderBy: 'updated_at',
      orderOptions: [
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Created at',
          value: 'created_at',
        },
        {
          text: 'Updated at',
          value: 'updated_at',
        },
      ],
      sortOptionss: [
        {
          text: 'Ascending',
          value: 'asc',
        },
        {
          text: 'Descending',
          value: 'desc',
        },
      ],
      currentPage: 1,
      perPage: 100,
      dir: false,
      columns: [
        {
          label: 'Id',
          field: 'id',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: true,
        },
        {
          label: 'Image',
          field: 'item_cover',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'SKU',
          field: 'sku',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-center',
          thClass: 'text-center',
        },

        {
          label: 'Brand',
          field: 'brand_logo',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Category',
          field: 'category_name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Item',
          field: 'item_name',
          tdClass: 'text-center',
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'Seller Name',
          field: 'user_seller_name',
          tdClass: 'text-center',
          thClass: 'text-center',
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'New/Used',
          field: 'is_new',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Name',
          field: 'product_name',
          tdClass: 'text-center',
          thClass: 'text-center',
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'Expire Date',
          field: 'variant_end_date',
          tdClass: 'text-center',
          thClass: 'text-center',
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'Sim Card',
          field: 'sim_cart_value',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Warranty',
          field: 'warranty_type',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'battery health',
          field: 'battery_health',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Imei',
          field: 'imei',
          tdClass: 'text-center',
          thClass: 'text-center',
        },

        {
          label: 'Quantity',
          field: 'quantity',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Seller price',
          field: 'original_price',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'sale price',
          field: 'sale_price',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Discount',
          field: 'offer_amount',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'profit',
          field: 'profit',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'visitors',
          field: 'visitors',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Created at',
          field: 'created_date',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: true,
        },
        {
          label: 'Updated at',
          field: 'updated_date',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: true,
        },
        {
          label: 'Action',
          field: 'actions',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      items: [],
      errors_back: [],
      showDismissibleAlert: false,
      variantsCounter: false,
      searchTerm: '',
      exportItems: [],
    }

  },
  computed: {
    sortOptions() {
      // Create an options list from our columns
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  mounted() { },
  created() {
    this.filteredData()
    this.fetchFilterData()
    this.authPermissions = this.$store.state.authPermissions

    if (this.authPermissions.includes('enabled-product')) {
      this.actionForm.bulkOptions.push('enabled')
    }
    if (this.authPermissions.includes('confirmed-product')) {
      this.actionForm.bulkOptions.push('confirmed')
    }
  },
  methods: {


    openReport(report) {
      window.open(report)
    },
    onRowClick(params) {
      // //console.log(params)
      this.$bvModal.show('modal-prevent-closing')

      this.variantData = params.row
      // //console.log(this.variantData)
      this.varaintDataPrice.vendorPayout =
        parseInt(this.variantData.sale_price) -
        parseInt(this.variantData.profit)
      this.varaintDataPrice.portalPrice = parseInt(this.variantData.sale_price)
      this.varaintDataPrice.rateEGP = parseInt(this.variantData.profit)
      this.varaintDataPrice.rateInPercent =
        (parseInt(this.variantData.profit) /
          parseInt(this.varaintDataPrice.vendorPayout)) *
        100
    },
    searchFetchData() {
      // this.fetchData()
      if (this.searchTerm.length >= 3 || this.searchTerm.length == 0) {

        this.fetchData()
      }
      //   if (this.searchTimer) {
      //     clearTimeout(this.searchTimer);
      //     this.searchTimer = null;
      // }
      // this.searchTimer = setTimeout(() => {
      //   console.log("hi")
      //   this.fetchData()
      // }, 3000);
    },
    fetchData() {
      this.errors_back = []
      this.showDismissibleAlert = false
      this.items.length = 0
      this.currentPage = 1

      var url = 'variants/ads/data-table'
      var data = {
        min_quantity: this.minQuan,
        max_quantity: this.maxQuan,
        pagi: this.perPage,
        // sort: this.sortBy,
        // order_by: this.orderBy,
      }
      if (this.orderBy != '') {
        data['order_by'] = this.orderBy
      }
      if (this.sortBy != '') {
        data['sort'] = this.sortBy
      }
      if (this.searchTerm != '') {
        data['search'] = this.searchTerm
      }
      if (this.brandModel.value != null && this.brandModel.value != '') {
        data['brand_id'] = this.brandModel.value
      }
      if (
        this.categoriesModel.value != null &&
        this.categoriesModel.value != ''
      ) {
        data['category_id'] = this.categoriesModel.value
      }
      if (this.itemsModel.value != null && this.itemsModel.value != '') {
        data['item_id'] = this.itemsModel.value
      }
      if (this.userModel.value != null && this.userModel.value != '') {
        data['vendor_id'] = this.userModel.value
      }
      if (this.selected == 'new' || this.selected == 'used') {
        data['variant_type'] = this.selected
      }
      if (this.selectedClassification == 1 || this.selectedClassification == 0) {
        data['is_portal'] = this.selectedClassification
      }
      if (this.statusModel != '' && this.statusModel != null) {
        data['status'] = this.statusModel
      }
      if (this.minPrice != null) {
        data['min_price'] = this.minPrice
      }
      if (this.maxPrice != null) {
        data['max_price'] = this.maxPrice
      }

      if (this.maxPrice != null && this.minPrice != null) {
        if (parseInt(data['max_price']) < parseInt(data['min_price'])) {
          this.errors_back = []
          this.showDismissibleAlert = true
          this.errors_back.push([['Max price must be greater than min price']])
          setTimeout(() => {
            this.showDismissibleAlert = false
          }, 3000)
        }
      }
      if (parseInt(this.maxQuan) < parseInt(this.minQuan)) {

        this.errors_back = []
        this.showDismissibleAlert = true
        this.errors_back.push([
          ['Max quantity must be greater than min quantity'],
        ])
        setTimeout(() => {
          this.showDismissibleAlert = false
        }, 3000)
      }
      axios
        .post(url, data)
        .then((response) => {
          const data = response.data.data.data
          this.totalRows = response.data.data.total
          this.items.length = 0
          this.items = response.data.data.data


        })
        .catch((err) => {
          //console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              })
            } else {
              this.errors_back = err.response.data.data.status
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
      if (this.items.length == 0) {
        this.setExportItems(this.items)

        this.items = []
      } else {
        this.setExportItems(this.items)
        return this.items
      }
    },
    fetchFilterData() {

      axios
        .get('variants/ads/data-table/filter')
        .then((response) => {
          this.brandsData = response.data.data.brands
          this.brandsData.forEach((el) => {
            return this.brands.push({
              text: el.name,
              value: el.id.toString(),
            })
          })
          // this.usersData = response.data.data.users
          // this.usersData.forEach((el) => {
          //   return this.users.push({
          //     text: el.firstname +' ' +el.lastname ,
          //     value: el.id.toString(),
          //   })
          // })
          this.categoriesData = response.data.data.categories
          this.categoriesData.forEach((el) => {
            return this.categories.push({
              text: el.name,
              value: el.id.toString(),
            })
          })
          this.itemsData = response.data.data.items
          this.itemsData.forEach((el) => {
            return this.itemsBucket.push({
              text: el.name,
              value: el.id.toString(),
            })
          })
          this.statusData = response.data.data.status
          this.minQuan = response.data.data.min_quantity
          this.maxQuan = response.data.data.max_quantity
          this.minPrice = response.data.data.min_price
          this.maxPrice = response.data.data.max_price
          this.type = response.data.data.type
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              })
            } else {
              this.errors_back = err.response.data.data.status
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    filteredData() {
      //console.log(this.currentPage)
      this.items.length = 0
      var url = 'variants/ads/data-table' + '?page=' + this.currentPage
      var data = {
        min_quantity: this.minQuan,
        max_quantity: this.maxQuan,
        pagi: this.perPage,
      }
      if (this.brandModel.value != null && this.brandModel.value != '') {
        data['brand_id'] = this.brandModel.value
      }
      if (
        this.categoriesModel.value != null &&
        this.categoriesModel.value != ''
      ) {
        data['category_id'] = this.categoriesModel.value
      }
      if (this.itemsModel.value != null && this.itemsModel.value != '') {
        data['item_id'] = this.itemsModel.value
      }
      if (this.userModel.value != null && this.userModel.value != '') {
        data['user_id'] = this.userModel.value
      }
      if (this.selected == 'new' || this.selected == 'used') {
        data['variant_type'] = this.selected
      }
      if (this.selectedClassification == 1 || this.selectedClassification == 0) {
        data['is_portal'] = this.selectedClassification
      }
      if (this.statusModel != '' && this.statusModel != null) {
        data['status'] = this.statusModel
      }
      if (this.minPrice != null) {
        data['min_price'] = this.minPrice
      }
      if (this.maxPrice != null) {
        data['max_price'] = this.maxPrice
      }
      if (this.searchTerm != '') {
        data['search'] = this.searchTerm
      }
      axios
        .post(url, data)
        .then((response) => {
          const data = response.data.data.data
          this.totalRows = response.data.data.total
          this.items.length = 0
          this.items = response.data.data.data
          //  //console.log(this.items)
          if (this.items.length == 0) {
            this.setExportItems(this.items)

            this.items = []
          } else {
            this.setExportItems(this.items)
            return this.items
          }
        })
        .catch((err) => {
          //console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              })
            } else {
              this.errors_back = err.response.data.data.status
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
      //console.log(this.currentPage)
    },
    RemoveExitem() {
      // //console.log('test')
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].brand_logo
        delete this.exportItems[ex].item_cover
        delete this.exportItems[ex].is_new
      }
    },
    setExportItems(data) {
      for (let index in data) {
        this.exportItems.push({
          sku: data[index].sku,
          seller_user: data[index].user_seller_name,
          category_name: data[index].category_name,

          type: data[index].is_new == false ? 'used' : 'true',
          item_name: data[index].item_name,
          product_name: data[index].product_name,
          original_price: data[index].original_price,
          price: data[index].price,
          profit: data[index].profit,
          sale_price: data[index].sale_price,
          offer_amount: data[index].offer_amount,
          quantity: data[index].quantity,
          status: data[index].status,

          battery_health: data[index].battery_health,
          Create_date: data[index].created_at,
          modified_at: data[index].updated_at,
        })
      }
    },
    resetFilter() {
      this.minQuan = 0
      this.maxQuan = 20
      this.minPrice = 100
      this.maxPrice = 100000

      this.brandModel = {
        text: 'Select Brand',
        value: '',
      }
      this.categoriesModel = {
        text: 'Select Category',
        value: '',
      }
      this.userModel = {
        text: 'Select User',
        value: '',
      }
      this.itemsModel = {
        text: 'Select Item',
        value: '',
      }
      this.statusModel = ''
      this.selected = 'all'
      this.selectedClassification = 'all'
      this.filteredData()
    },
    deDuctCountOfVariant() {
      this.selectedRowsId = []
      this.x = this.$refs['my-table'].selectedRows
      this.x.forEach((element) => {
        this.selectedRowsId.push(element.id)
      })
      if (this.selectedRowsId == 0 || this.selectedRowsId == null) {
        this.errors_back = []
        this.showDismissibleAlert = true
        this.errors_back.push([['Please Add at least one variant']])
        setTimeout(() => {
          this.showDismissibleAlert = false
        }, 6000)
      } else {
      }
    },
    applyBulkStatusUpdate() {
      this.selectedRowsId = []
      this.x = this.$refs['my-table'].selectedRows
      this.x.forEach((element) => {
        this.selectedRowsId.push(element.id)
      })
      axios
        .post('/variants/update-status/pluck', {
          variants: this.selectedRowsId,
          status: this.bulkActionStatus,
        })
        .then((response) => {
          if (response.data.success) {
            this.fetchData()
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            //console.log(err.response.data.data)
            if (err.response.data.data) {
              if (Array.isArray(err.response.data.data)) {
                this.errors_back.push(err.response.data.data)
              } else {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              }
            } else {
              //console.log(err.response.data.data)
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    applyBulkPriceUpdate() {
      this.selectedRowsId = []
      this.variantsBucket = this.$refs['my-table'].selectedRows
      this.variantsBucket.forEach((element) => {
        this.selectedRowsId.push(element.id)
      })
      var data = {
        variants: this.selectedRowsId,
        type: null,
        rate: null,
        original_price: null,
        offer: {
          type: null,
          amount: null,
          usernumbers: null,
          startdate: null,
          enddate: null,
        },
      }
      if (
        this.actionForm.offerAmount != null &&
        this.actionForm.offerAmount != ''
      ) {
        data.offer.amount = this.actionForm.offerAmount
        data.offer.type = this.actionForm.offerType.value
        data.offer.usernumbers = this.actionForm.userNumber
        data.offer.startdate = this.actionForm.startDate
        data.offer.enddate = this.actionForm.endDate
      } else {
        delete data.offer
      }
      if (
        this.actionForm.rateType.value != null &&
        this.actionForm.rateType.value != ''
      ) {
        data.type = this.actionForm.rateType.value
      }
      if (this.actionForm.rate != null && this.actionForm.rate != '') {
        data.rate = this.actionForm.rate
      }
      if (
        this.actionForm.originalPrice != null &&
        this.actionForm.originalPrice != ''
      ) {
        data.original_price = this.actionForm.originalPrice
      }
      if (this.selectedRowsId == 0 || this.selectedRowsId == null) {
        this.variantsCounter = true
      } else {
      }
      this.errors_back = []
      this.showDismissibleAlert = false
      axios
        .post('/variants/update-price/pluck', data)
        .then((response) => {
          if (response.data.success) {
            this.fetchData()
          } else {
            // //console.log(response.data.message)
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.datas.status,
              })
            } else {
              this.errors_back = err.response.data.data.status
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>
<style lang="scss">
.vgt-table thead th,
.vgt-table td,
.vgt-table td span {
  white-space: nowrap !important;
  text-transform: capitalize !important;
}

table.vgt-table td {
  vertical-align: middle !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #08308B !important;
  background-color: #08308B !important;
}

.btn-color {
  background-color: #08308B !important;
  border-color: #08308B !important;
}

table.vgt-table td {
  padding: 0 !important;
}

.vgt-table {
  min-height: 37vh !important;
}
</style>
  